<template>
  <div>
    <p>Redirecionando para www.promaxima.com.br...</p>
  </div>
</template>

<script>
export default {
  mounted() {
    window.location.href = 'https://www.promaxima.com.br';
  },
};
</script>

<style scoped>
/* Estilos opcionais para personalizar a página */
</style>
